@import '../_breakpoints';

#top-nav-container {
    padding-bottom: 40px;
}

.navbar {
    flex-direction: column;
    background-color: #fff;
    border-bottom: 2px solid #000;
}

.navbar-brand {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}

.navbar-nav {
    justify-content: center;
}

.nav-item {
    margin: 0 5px;
}

@media (max-width: $sm-max) {
    .navbar-brand {
        display: inline-block;
        width: auto;
        margin-right: auto;
        margin-bottom: 0;
    }
    
    .navbar-toggler {
        display: inline-block;
        margin-left: auto;
    }

    .navbar-collapse {
        justify-content: center;
        text-align: center;
    }

    .nav-link {
        width: 100%;
    }
}
