@import "../node_modules/bootstrap/scss/bootstrap";
@import "theme";
@import "breakpoints";
@import "utilities";

html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: hidden;
}

.homepage-body {
    background-color: #282033;
    color: #dbd4e2;
}

.media-border {
    outline: 0.5rem solid white;
}

.media-container figure {
    margin: 0;
}

.media-container figcaption {
    padding: 1rem !important;
    color: white;
    margin-top: 0.5rem;
}

// Phones
@media (max-width: 575px) {
    .page-heading { text-align: center !important; }
    .media-container.media-border {
        border-left-width: 1rem !important;
        border-top-width: 2rem !important;
    }
    .home-container {
        padding-top: 0 !important;
    }
    .home-container .self-portrait {
        max-height: 165px !important;
    }
    .home-container .links-section {
        margin-top: 1rem !important;
    }
    .home-container .links-section .btn {
        padding: 0.25rem 0.35rem !important;
        font-size: 75% !important;
    }
}

// // Tablets
// @media (min-width: 576px) and (max-width: 767px) {

// }

// // Laptops
// @media (min-width: 768px) and (max-width: 991px) {

// }

// // Small Desktops
// @media (min-width: 992px) and (max-width: 1199px) {

// }

// // Large Desktops
// @media (min-width: 1200px) and (max-width: 1399px) {

// }

// // Extra Large Desktops
// @media (min-width: 1400px) {

// }